import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
  Modal,
  InputGroup,
  FormControl,
  Form
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { DoKYC as textLang } from 'staticData/languages';
import Wizard from '../../../common/wizard/Wizard';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import PlacesAutocomplete from 'react-places-autocomplete';
import { trackEvent } from 'services/externals/mixpanel';
import 'onfido-sdk-ui/split/css';

const DoKYC = ({
  doKYCShow,
  setDoKYCShow,
  step,
  setKycStatus,
  setAddKeysShow,
  setStep
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  var onfido = {};
  const [disableButton, setDisableButton] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    tax_code: '',
    address: '',
    place_id: '',
    mobile: ''
  });
  const [validTaxCode, setValidTaxCode] = useState(true);
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [processingKYC, setProcessingKYC] = useState(false);
  const [onError, setOnError] = useState(false);
  const [stopProcessing, setStopProcessing] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchOptions = {
    types: ['address']
  };
  var intervalGetKYC;
  var KYCErrorCount = 0;
  var KYCComplete = false;

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getKYC = async () => {
    setDisableButton(true);
    let kycResponse = await endpointInterface(
      lang,
      'backend',
      'getKYC',
      'get',
      true
    );
    if (!kycResponse.validResponse) {
      toast.error(kycResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    } else {
      if (
        kycResponse.data.kyc_status !== 'approved' &&
        kycResponse.data.kyc_status !== 'contact_support'
      ) {
        if (KYCErrorCount < 5) KYCErrorCount += 1;
        else {
          clearInterval(intervalGetKYC);
          setStopProcessing(true);
          // window.location.reload();
          // await createKycSession();
        }
      } else {
        clearInterval(intervalGetKYC);
        setStep(step + 1);
        setKycStatus('approved');
        setDoKYCShow(false);
        setProcessingKYC(false);
        setAddKeysShow(true);
      }
    }
  };

  const handleGetKYC = async () => {
    setDoKYCShow(true);
    setProcessingKYC(true);
    intervalGetKYC = setInterval(async () => {
      await getKYC();
    }, 3000);
    await getKYC();
  };

  const handlePlaceChange = address => {
    setFormData({ ...formData, address });
    setShowSuggestions(true);
  };

  const handleSelect = value => {
    setFormData({
      ...formData,
      address: value.description,
      place_id: value.placeId
    });
    setShowSuggestions(false);
  };

  const createKycSession = async () => {
    setValidTaxCode(true);
    setDisableButton(true);
    let form = new FormData();
    form.append('first_name', formData.name);
    form.append('last_name', formData.surname);
    form.append('tax_code', formData.tax_code);
    form.append('place_id', formData.place_id);
    form.append('mobile_number', formData.mobile);
    let kycSessionResponse = await endpointInterface(
      lang,
      'backend',
      'createKycSession',
      'post',
      true,
      form
    );
    if (!kycSessionResponse.validResponse) {
      if (kycSessionResponse.errorID === 'invalidTaxCode')
        setValidTaxCode(false);
      toast.error(kycSessionResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      setDisableButton(false);
    } else {
      if (KYCComplete) window.location.reload();
      else {
        const Onfido = await import('onfido-sdk-ui');
        onfido = Onfido.init({
          useModal: true,
          isModalOpen: true,
          onModalRequestClose: () => {
            // Update options with the state of the modal
            onfido.setOptions({ isModalOpen: false });
            if (KYCComplete) handleGetKYC();
            else {
              setDisableButton(false);
              setDoKYCShow(true);
            }
          },
          token: kycSessionResponse.data.sdk_token,
          workflowRunId: kycSessionResponse.data.workflow_run_id,
          onComplete: () => {
            trackEvent('kyc', {
              status_event: 'completed',
              where: 'wallet page'
            });
            KYCComplete = true;
          },
          onUserExit: () => {
            trackEvent('kyc', {
              status_event: 'user exit',
              where: 'wallet page'
            });
            setDisableButton(false);
            setDoKYCShow(true);
            setOnError(true);
          },
          onError: () => {
            trackEvent('kyc', {
              status_event: 'onfido error',
              where: 'wallet page'
            });
            setDisableButton(false);
            setDoKYCShow(true);
            setOnError(true);
          }
        });
        setDoKYCShow(false);
      }
    }
  };

  return (
    <Modal
      show={doKYCShow}
      onHide={() => setDoKYCShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Wizard step={step} />
      </Modal.Header>
      {processingKYC ? (
        stopProcessing ? (
          <Modal.Body className="text-center">
            <h5>{textLang.endProcessing[lang]}</h5>
            <h5>{textLang.endProcessing1[lang]}</h5>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div style={{ height: '150px', paddingTop: '50px' }}>
              <LoadingSpinner
                subText={textLang.processing[lang]}
              ></LoadingSpinner>
            </div>
          </Modal.Body>
        )
      ) : onError ? (
        <>
          <Modal.Body className="text-center">
            <h5>{textLang.retryText[lang]}</h5>
            <h5>
              {textLang.retryText1[lang]}
              <a href="mailto:support@hodlie.net"> support@hodlie.net</a>
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                variant="outline-success"
                className="me-2 mb-1 "
                icon="user"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => setOnError(false)}
              >
                {textLang.retry[lang]}
              </IconButton>
            </Flex>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            <h4 className="text-center">{textLang.title[lang]}</h4>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.name[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={handleFieldChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.surname[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.surname}
                    name="surname"
                    onChange={handleFieldChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.tax_code[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.tax_code}
                    name="tax_code"
                    onChange={handleFieldChange}
                    isInvalid={!validTaxCode}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="mb-3">
                <Form.Label>{textLang.address[lang]}</Form.Label>
                <PlacesAutocomplete
                  value={formData.address}
                  onChange={handlePlaceChange}
                  onSelect={handlePlaceChange}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                    <>
                      <FormControl
                        {...getInputProps({
                          className: 'location-search-input'
                        })}
                      />
                      {showSuggestions && (
                        <div className="autocomplete-dropdown-container border rounded">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = suggestion.active
                              ? {
                                  backgroundColor: 'var(--falcon-300)',
                                  cursor: 'pointer'
                                }
                              : {
                                  backgroundColor: 'var(--falcon-200)',
                                  cursor: 'pointer'
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                                onClick={() => handleSelect(suggestion)}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.mobile[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.mobile}
                    name="mobile"
                    onChange={handleFieldChange}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md={6}
                className="pb-2"
                style={{ display: 'flex', flexDirection: 'row' }}
                onClick={() => setAcceptedTos(!acceptedTos)}
              >
                <Form.Check
                  type="checkbox"
                  className="me-2"
                  style={{ cursor: 'pointer' }}
                  name="acceptedTos"
                  checked={acceptedTos}
                />
                <Form.Label>
                  <p
                    className="m-0 p-0"
                    dangerouslySetInnerHTML={{
                      __html: textLang.tos[lang]
                    }}
                  ></p>
                </Form.Label>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
              <IconButton
                variant="outline-danger"
                className="me-2 mb-1 "
                icon="times"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => setDoKYCShow(false)}
              >
                {textLang.close[lang]}
              </IconButton>
            </Flex>
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                variant="outline-success"
                className="me-2 mb-1 "
                icon="user"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => createKycSession()}
                disabled={
                  !formData.name ||
                  !formData.surname ||
                  !formData.tax_code ||
                  !formData.address ||
                  !formData.place_id ||
                  !acceptedTos ||
                  disableButton
                }
              >
                {textLang.verify[lang]}
              </IconButton>
            </Flex>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

DoKYC.propTypes = {
  doKYCShow: PropTypes.bool.isRequired,
  setDoKYCShow: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  setKycStatus: PropTypes.func.isRequired,
  setAddKeysShow: PropTypes.func
};

export default DoKYC;
