import React, { useState, useContext } from 'react';
import { Row, Nav, Card, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { cleanCache } from 'services/cache';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Profile as textLang, monthsList } from 'staticData/languages';
import { firstProTier, secondProTier, traderProTier } from 'staticData/common';
import FormTitle from 'pages/common/FormTitle';
import { googleLogout } from '@react-oauth/google';
import ConfirmMail from 'pages/common/modals/ConfirmMail';
import { useHistory } from 'react-router-dom';
import { resetUser } from 'services/externals/mixpanel';

const Profile = () => {
  const {
    config: { lang },
    userInfos,
    setUserInfos,
    setLoggedIn
  } = useContext(AppContext);
  const routerHistory = useHistory();
  var defaultTooltipText = textLang.copy[lang];
  const userDate = new Date(userInfos.created_at * 1000);
  const createdAt =
    lang === 'it-IT'
      ? String(userDate.getDate()) +
        ' ' +
        monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        String(userDate.getFullYear())
      : monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        userDate.getDate() +
        ' ' +
        userDate.getFullYear();
  const referral = userInfos.my_referral;
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [ConfirmMailShow, setConfirmMailShow] = useState(false);

  const copyReferral = async () => {
    navigator.clipboard.writeText(referral);
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  const handleLogout = async () => {
    await googleLogout();
    let logoutResponse = await endpointInterface(
      lang,
      'backend',
      'logoutPage',
      'post',
      true
    );
    if (logoutResponse.validResponse) {
      await cleanCache(0);
      setLoggedIn(false);
      setUserInfos({});
      resetUser();
      routerHistory.push('/login');
    } else {
      toast.error(logoutResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  return (
    <>
      <ConfirmMail
        ConfirmMailShow={ConfirmMailShow}
        setConfirmMailShow={setConfirmMailShow}
        step={0}
        withHeader={false}
        userCanClose={true}
      />
      <Row md={12}>
        <FormTitle
          icon="user"
          title={textLang.titleProfile[lang]}
          subtitle={textLang.subtitleProfile[lang]}
        />
      </Row>
      <Row className="mb-3 gy-3">
        <Card>
          <Card.Body>
            {userInfos.pro_tier >= firstProTier ? (
              <Row className="pb-2">
                <Row>
                  <Col sm={1} style={{ width: '3.333333%' }}>
                    {userInfos.pro_tier >= traderProTier ? (
                      <FontAwesomeIcon
                        icon={'chart-bar'}
                        className="text-success"
                      />
                    ) : (
                      <FontAwesomeIcon icon={'crown'} color="orange" />
                    )}
                  </Col>
                  <Col xs={10} sm={10} md={8} xl={8}>
                    <Row>
                      <Col xs={10} sm={7}>
                        <p>
                          <b>
                            {userInfos.pro_tier >= traderProTier
                              ? 'Private'
                              : userInfos.pro_tier === secondProTier
                              ? 'Investor'
                              : 'Explorer'}
                          </b>
                        </p>
                      </Col>
                      <Col xs={2} sm={5}>
                        <OverlayTrigger
                          style={{ marginLeft: '4px', marginBottom: '1px' }}
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="manageYourPlan">
                              {textLang.manageYourPlan[lang]}
                            </Tooltip>
                          }
                        >
                          <div style={{ width: 'fit-content' }}>
                            <Link to="/account/billings">
                              <IconButton
                                style={{ padding: '0 0.35rem' }}
                                variant="falcon-default"
                                icon="edit"
                              ></IconButton>
                            </Link>
                          </div>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            ) : (
              <Row className="pb-2">
                <Row>
                  <Col sm={1} style={{ width: '3.333333%' }}>
                    <FontAwesomeIcon icon="home" color="#0074d9" />
                  </Col>
                  <Col className="d-flex flex-row">
                    <p>{textLang.basicUser[lang]}</p>
                  </Col>
                </Row>
              </Row>
            )}
            <Row className="pb-2">
              <Row>
                <Col sm={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="at" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={userInfos.email_confirmed ? 10 : 12} sm={7}>
                      <p>
                        <b>{userInfos.email}</b>
                      </p>
                    </Col>
                    <Col xs={userInfos.email_confirmed ? 2 : 12} sm={5}>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changeusername">
                            {userInfos.email_confirmed
                              ? textLang.mailVerified[lang]
                              : textLang.mailNotVerified[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <IconButton
                            style={{
                              padding: '0 0.35rem',
                              cursor: userInfos.email_confirmed
                                ? 'default'
                                : 'pointer'
                            }}
                            variant={
                              userInfos.email_confirmed
                                ? 'falcon-success'
                                : 'falcon-danger'
                            }
                            icon={
                              userInfos.email_confirmed
                                ? 'check-circle'
                                : 'times'
                            }
                            onClick={() =>
                              userInfos.email_confirmed
                                ? null
                                : setConfirmMailShow(true)
                            }
                          >
                            {userInfos.email_confirmed
                              ? ''
                              : textLang.notConfirmed[lang]}
                          </IconButton>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row className="pb-2">
              <Row>
                <Col xs={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="gift" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={10} sm={7}>
                      <p>
                        Referral Code: <b>{referral}</b>
                      </p>
                    </Col>
                    <Col xs={2} sm={5}>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="copyReferral">{tooltipText}</Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <IconButton
                            style={{ padding: '0 0.35rem' }}
                            variant="falcon-default"
                            icon="copy"
                            onClick={copyReferral}
                          ></IconButton>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row className="pb-2">
              <Row>
                <Col sm={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="calendar" />
                </Col>
                <Col>
                  <p>
                    Hodlier {textLang.from[lang]} <b>{createdAt}</b>
                  </p>
                </Col>
              </Row>
            </Row>
            <Row className="pb-2">
              <Nav.Link
                className="px-2 theme-control-toggle"
                onClick={() => handleLogout()}
              >
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="ThemeColor">{textLang.logout[lang]}</Tooltip>
                  }
                >
                  <Row
                    className="ps-2 custom-link-color"
                    style={{ maxWidth: 'fit-content' }}
                  >
                    <Col sm={1} style={{ width: '3.333333%' }}>
                      <FontAwesomeIcon icon="power-off" className="fs-0" />
                    </Col>
                    <Col style={{ maxWidth: 'fit-content' }}>
                      <b className="text-danger">Logout</b>
                    </Col>
                  </Row>
                </OverlayTrigger>
              </Nav.Link>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default Profile;
