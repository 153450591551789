/* eslint-disable react/react-in-jsx-scope */
import { CiSettings } from 'react-icons/ci';
import { /*FaRocket,*/ FaFile, FaQuestion, FaWallet } from 'react-icons/fa';
import { FaSpinner } from 'react-icons/fa6';
import { FiHexagon, FiUser } from 'react-icons/fi';
import { SlPresent } from 'react-icons/sl';
import { Account as textLang } from 'staticData/languages';

export const account = [
  {
    icon: <FiUser className="text-primary fs-2" />,
    color: 'primary',
    title: textLang.profile,
    description: textLang.profileDescr,
    link: 'account/profile'
  },
  {
    icon: <FiHexagon className="text-info fs-2" />,
    color: 'info',
    title: textLang.billing,
    description: textLang.billingDescr,
    link: 'account/billings',
    onlyPro: true
  },
  {
    icon: <SlPresent className="text-warning fs-2" />,
    color: 'warning',
    title: textLang.referral,
    description: textLang.referralDescr,
    link: '/rewards'
  },
  {
    icon: <CiSettings className="text-secondary fs-2" />,
    color: 'secondary',
    title: textLang.settings,
    description: textLang.settingsDescr,
    link: 'account/settings'
  },
  {
    icon: <FaWallet className="text-success fs-2" />,
    color: 'success',
    title: textLang.wallet,
    description: textLang.walletDescr,
    link: '/account/wallet-management'
  },
  {
    icon: <FaSpinner className="text-success fs-2" />,
    color: 'success',
    title: textLang.becomeTrader,
    description: textLang.becomeTraderDescr,
    link: '/account/trader-procedure',
    onlyWannaBeTrader: true
  }
];
export const miscellaneous = [
  {
    icon: <FaFile className="text-info fs-2" />,
    color: 'info',
    title: textLang.manifest,
    description: textLang.manifestDescr,
    modalBody: {
      'en-EN': (
        <iframe
          src="https://app.hodlie.finance/pdf/Hodlie_Manifesto_EN.pdf"
          width="100%"
          height="600"
        ></iframe>
      ),
      'it-IT': (
        <iframe
          src="https://app.hodlie.finance/pdf/Hodlie_Manifesto.pdf"
          width="100%"
          height="600"
        ></iframe>
      )
    }
  },
  // {
  //   icon: <FaRocket className="text-info fs-2" />,
  //   color: 'info',
  //   title: textLang.tutorial,
  //   description: textLang.tutorialDescr,
  //   link: 'usage-guide'
  // },
  {
    icon: <FaQuestion className="text-info fs-2" />,
    color: 'info',
    title: textLang.faq,
    description: textLang.faqDescr,
    link: 'faq'
  }
];
