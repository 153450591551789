import React, { useContext, useState, useEffect } from 'react';
import logoHodlie from 'assets/img/hodlie/hodlie_logo.png';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import AppContext from 'context/Context';
import {
  firstProTier,
  goldColor,
  secondProTier,
  traderProTier,
  isTraderCompleted
} from 'staticData/common';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { monthsList } from 'staticData/languages';
import { Account, Billings as textLang } from 'staticData/languages';
import TraderModal from 'pages/common/modals/TraderModal';
import { trackEvent } from 'services/externals/mixpanel';
import PricingModal from 'pages/Pricing/modals';

const Billings = ({ getInfo }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const routerHistory = useHistory();
  const [traderModalShow, setTraderModalShow] = useState(false);
  // get params in url
  const [showModalPricing, setShowModalPricing] = useState(false);
  const [upgradePricePreview, setUpgradePricePreview] = useState();

  const manageSubscription = async (type = null) => {
    const resp = await endpointInterface(
      lang,
      'backend',
      'manageUserSubscription',
      'post',
      true
    );
    if (resp.responseStatus !== 200) {
      // TODO: Manage backend error, show a popup or something?
      console.error('Error occurred while managing subscription');
      return;
    }
    trackEvent('subscription button', {
      event: type
    });
    window.location.href = resp.data.redirect_url;
  };

  const getUpgradePreview = async () => {
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'upgradePreview',
      httpMethod: 'post',
      usingCredentials: true,
      bodyParams: {
        tier: secondProTier,
        months: userInfos.subscription.recurring_months
      }
    });
    if (resp.validResponse) setUpgradePricePreview(resp.data.amount_due);
  };

  const disableSubsButton = !(
    userInfos.subscription &&
    Object.keys(userInfos.subscription).length > 0 &&
    userInfos.subscription.management_allowed
  );

  const userDate = userInfos.subscription
    ? new Date(userInfos.subscription.start_date * 1000)
    : null;
  const createdAt = userInfos.subscription
    ? lang === 'it-IT'
      ? String(userDate.getDate()) +
        ' ' +
        monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        String(userDate.getFullYear())
      : monthsList.months[lang][userDate.getMonth()] +
        ' ' +
        userDate.getDate() +
        ' ' +
        userDate.getFullYear()
    : null;
  const subType = userInfos.subscription
    ? userInfos.subscription.recurring_months === 1
      ? textLang.monthly[lang]
      : userInfos.subscription.recurring_months === 12
      ? textLang.yearly[lang]
      : null
    : null;

  useEffect(() => {
    // get upgrade price preview
    if (
      userInfos.pro_tier === firstProTier &&
      !userInfos.subscription?.trialing
    )
      getUpgradePreview();
  }, []);

  return (
    <>
      <TraderModal
        showModal={traderModalShow}
        setShowModal={setTraderModalShow}
        getInfo={getInfo}
      />
      <PricingModal
        getInfo={() => null}
        showModal={showModalPricing}
        setShowModal={setShowModalPricing}
        whatToDo={'upgrade'}
        newProTier={2}
        type="account/billings"
      />
      <Card className="mb-3">
        <Card.Body>
          <Row className="justify-content-between align-items-center">
            <Col xs={userInfos.trader ? 'auto' : 12} md="auto">
              <h5 className="mb-2 mb-md-0">
                {textLang.manageSubscription[lang]}
              </h5>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Row className="align-items-center text-center mb-3">
            <Col
              sm={6}
              className="text-sm-start"
              style={{ display: 'contents' }}
            >
              <Flex className="ms-3">
                <img src={logoHodlie} alt="invoice" width={150} />
              </Flex>
            </Col>
            <Col className="text-sm-end mt-3 mt-sm-0">
              <h3 className="mb-3">
                {userInfos.pro_tier >= traderProTier ? (
                  <span className="text-success">
                    <span>Private</span>
                  </span>
                ) : userInfos.pro_tier === secondProTier ? (
                  <span style={{ color: goldColor }}>
                    <span>Investor</span>
                  </span>
                ) : (
                  <span style={{ color: goldColor }}>
                    <span>Explorer</span>
                  </span>
                )}
              </h3>
              <h5>
                <b>{userInfos.username}</b>
              </h5>
              <p className="fs--1 mb-0">{userInfos.email}</p>
            </Col>
            <Col xs={12} className="mt-4 mb-3">
              <Row className="text-start text-sm-center justify-content-sm-center">
                {createdAt && (
                  <Col sm={4}>
                    <p className="mb-1 mt-3">{textLang.subsFrom[lang]}</p>
                    <h5>{createdAt}</h5>
                  </Col>
                )}
                {/* {EndAt && (
                  <Col sm={4}>
                    <p className="mb-1 mt-3">{textLang.subEnd[lang]}</p>
                    <h5>{EndAt}</h5>
                  </Col>
                )} */}
                {subType && (
                  <Col sm={4}>
                    <p className="mb-1 mt-3">{textLang.subType[lang]}</p>
                    <h5>
                      {subType}
                      {userInfos.subscription.trialing
                        ? ` (${textLang.trial[lang]})`
                        : ''}
                    </h5>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12} className="py-2 border-bottom">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <span className="text-start">
                  <p className="mb-0 text-dark">{textLang.subDetails[lang]}</p>
                </span>
                <span className="d-flex justify-content-center mt-2 mt-sm-0">
                  <Button
                    variant="outline-primary"
                    className="d-flex d-sm-block align-items-center"
                    onClick={() => manageSubscription('view')}
                    disabled={disableSubsButton}
                  >
                    {textLang.subDetailsButton[lang]}
                    <FontAwesomeIcon
                      className="ms-2"
                      icon="external-link-alt"
                    />
                  </Button>
                </span>
              </div>
            </Col>
            {userInfos.pro_tier === firstProTier ? (
              <Col xs={12} className="py-2 border-bottom">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <span className="text-start">
                    <p className="mb-0 text-dark">
                      {textLang.investorUpgrade[lang]}
                      {upgradePricePreview != null && (
                        <span>
                          {textLang.for[lang]}
                          <b>€{upgradePricePreview}</b>
                          {process.env.REACT_APP_ENV === 'DEV' && ' \u{1F525}'}
                        </span>
                      )}
                    </p>
                    <small>{textLang.investorUpgradeDescr[lang]}</small>
                  </span>
                  <span className="d-flex justify-content-center mt-2 mt-sm-0">
                    <Button
                      variant="primary"
                      onClick={() => setShowModalPricing(true)}
                    >
                      {textLang.upgradeButtton[lang]}
                    </Button>
                  </span>
                </div>
              </Col>
            ) : (
              userInfos.pro_tier === secondProTier && (
                <Col xs={12} className="py-2 border-bottom">
                  <div className="d-block d-sm-flex justify-content-between align-items-center">
                    <span className="text-start">
                      <p className="mb-0 text-dark">
                        {textLang.privateUpgrade[lang]}
                      </p>
                      <small>{textLang.privateUpgradeDescr[lang]}</small>
                    </span>
                    <span className="d-flex justify-content-center mt-2 mt-sm-0">
                      <Button
                        variant="outline-success"
                        onClick={() =>
                          !userInfos.trader
                            ? setTraderModalShow(true)
                            : userInfos.trader && !isTraderCompleted(userInfos)
                            ? routerHistory.push('/account/trader-procedure')
                            : null
                        }
                        disabled={isTraderCompleted(userInfos)}
                      >
                        {!userInfos.trader ? (
                          <p className="mb-0">{Account.becomeTrader[lang]}</p>
                        ) : userInfos.trader ? (
                          !isTraderCompleted(userInfos) && (
                            <p className="mb-0">{Account.becomeTrader[lang]}</p>
                          )
                        ) : (
                          userInfos.trader && <p className="mb-0">Private</p>
                        )}
                      </Button>
                    </span>
                  </div>
                </Col>
              )
            )}
            <Col xs={12} className="py-2 border-bottom">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <span className="text-start">
                  <p className="mb-0 text-dark">
                    {textLang.updateMethod[lang]}
                  </p>
                </span>
                <span className="d-flex justify-content-center mt-2 mt-sm-0">
                  <Button
                    variant="outline-primary"
                    className="d-flex d-sm-block align-items-center"
                    onClick={() => manageSubscription('update payment method')}
                    disabled={disableSubsButton}
                  >
                    {textLang.updateButtton[lang]}
                    <FontAwesomeIcon
                      className="ms-2"
                      icon="external-link-alt"
                    />
                  </Button>
                </span>
              </div>
            </Col>
            <Col xs={12} className="py-2 border-bottom">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <span className="text-start">
                  <p className="mb-0 text-dark">{textLang.delete[lang]}</p>
                </span>
                <span className="d-flex justify-content-center mt-2 mt-sm-0">
                  <Button
                    variant="outline-danger"
                    className="d-flex d-sm-block align-items-center"
                    onClick={() => manageSubscription('delete')}
                    disabled={disableSubsButton}
                  >
                    {textLang.deleteButtton[lang]}
                    <FontAwesomeIcon
                      className="ms-2"
                      icon="external-link-alt"
                    />
                  </Button>
                </span>
              </div>
            </Col>
          </Row>
          <p className="fs--1 mb-0">
            <strong>{textLang.note[lang]}</strong> {textLang.noteDescr[lang]}
          </p>
          <hr />
          <Row>
            {(userInfos.pro_tier < secondProTier ||
              (userInfos.pro_tier === traderProTier &&
                !isTraderCompleted(userInfos))) && (
              <Col xs={12} className="py-2">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <span className="text-start">
                    <p className="mb-0 text-dark">
                      {textLang.privateUpgrade[lang]}
                    </p>
                    <small>{textLang.privateUpgradeDescr[lang]}</small>
                  </span>
                  <span className="d-flex justify-content-center mt-2 mt-sm-0">
                    <Button
                      variant="outline-success"
                      onClick={() =>
                        !userInfos.trader
                          ? setTraderModalShow(true)
                          : userInfos.trader && !isTraderCompleted(userInfos)
                          ? routerHistory.push('/account/trader-procedure')
                          : null
                      }
                      disabled={isTraderCompleted(userInfos)}
                    >
                      {!userInfos.trader ? (
                        <p className="mb-0">{Account.becomeTrader[lang]}</p>
                      ) : userInfos.trader && !isTraderCompleted(userInfos) ? (
                        <p className="mb-0">Private</p>
                      ) : (
                        userInfos.trader && <p className="mb-0">Private</p>
                      )}
                    </Button>
                  </span>
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

Billings.propTypes = {
  getInfo: PropTypes.function
};

export default Billings;
