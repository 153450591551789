import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ComingSoon from 'components/pages/ComingSoon';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import AppContext from 'context/Context';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import { frontendRoutes } from 'router/routes';
import { MainLayout as textLang } from 'staticData/languages';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import LoadingSpinner from '../components/utilities/LoadingSpinner';
import { toast } from 'react-toastify';
import maintenance from 'assets/img/animated-icons/maintenance.json';
import { teamLevel, maintenanceVariable } from 'staticData/common';
import 'assets/scss/custom/mainLayout.scss';
import NavbarBottom from 'components/navbar/bottom/NavbarBottom';
import Event from 'pages/common/Event';
import HodlieRouter from 'router/HodlieRouter';
import { sharedState } from 'staticData/common';
import UsetifulScriptLoader from 'services/externals/usetiful/ScriptLoader';
import { deepEqual } from 'helpers/utils';
import { initClarity } from 'services/externals/clarity';

const MainLayout = () => {
  const {
    config: { isFluid, navbarPosition, lang },
    setUserInfos,
    userInfos,
    setLoggedIn,
    loggedIn
  } = useContext(AppContext);
  const { hash, pathname } = useLocation();
  var pathString = pathname;
  while (pathString.length > 1 && pathString.slice(-1) === '/') {
    pathString = pathString.slice(0, -1);
  }
  var subPath = pathString.split('/');
  subPath = subPath.slice(1).map(i => '/' + i.toLowerCase());
  const isKanban = pathname.includes('kanban');
  // maintenance Part
  const [isInMaintenance, setIsInMaintenance] = useState(false);
  const [isBackendDown, setIsBackendDown] = useState(false);
  const [loadedContent, setLoadedContent] = useState(false);

  var intervalBackendVersion;
  var checkMaintenance = false;
  var backendErrorCount = 0;

  // frontend routes
  var verticalNavPath = false;
  var topNavPath = false;
  var bottomNavPath = false;
  var credentialsPath = false;
  frontendRoutes.map(item => {
    subPath.map(path => {
      if (path === item.to.toLowerCase()) {
        verticalNavPath = item.navbarVertical;
        topNavPath = item.navbarTop;
        bottomNavPath = item.navbarBottom;
        credentialsPath = item.withCredentials;
        return;
      }
    });
  });

  const checkBackendMaintenance = async () => {
    let backendResponse = await endpointInterface(
      lang,
      'backend',
      'backendVer',
      'get',
      true
    );
    var wait = true;
    if (backendResponse.validResponse) {
      clearInterval(intervalBackendVersion);
      if (backendResponse.data === maintenanceVariable) {
        setIsInMaintenance(true);
        checkMaintenance = true;
        wait = false;
      } else {
        backendErrorCount = 0;
        wait = false;
        if (checkMaintenance) window.location.reload();
      }
    } else backendErrorCount += 1;
    if (backendErrorCount >= 3) {
      setIsBackendDown(true);
      checkMaintenance = true;
    }
    return wait;
  };

  const logStatus = async (returnData = false) => {
    var params = { page: window.location.pathname };
    let tmpInfos = await endpointInterface(
      lang,
      'backend',
      'getUserInfo',
      'get',
      true,
      params
    );
    if (!deepEqual(tmpInfos.data, userInfos)) {
      setUserInfos(tmpInfos.data);
      // set the userInfos in the sharedState (used for cache)
      sharedState.userInfos = tmpInfos.data;
    }
    if (tmpInfos.validResponse) {
      setLoggedIn(true);
    } else {
      if (credentialsPath) {
        toast.error(tmpInfos.responseMessage, { closeButton: false });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
        setLoggedIn(false);
      }
    }
    if (returnData) return tmpInfos.data;
  };

  const updateTitle = () => {
    const defaultTitle = 'Hodlie';
    // Create a URL object to easily extract components from the URL
    const url = new URL(window.location.href);
    // Get pathname without query parameters
    const cleanPathname = url.pathname;
    // Find the corresponding route using the clean pathname
    const currentRoute = frontendRoutes.find(
      route => route.to === cleanPathname
    );
    let routeName;
    // Determine route name based on `currentRoute` existence and format
    if (currentRoute) {
      if (typeof currentRoute.name === 'object') {
        // If `name` is an object, use the italian name first, then the english one
        routeName = currentRoute.name['it-IT'] || currentRoute.name['en-EN'];
      } else {
        // If `name` is not an object, use it directly
        routeName = currentRoute.name;
      }
    } else {
      // If route doesn't exist, format the clean pathname:
      routeName = cleanPathname
        .slice(1)
        .replace(/-/g, ' ') // Replace hyphens with spaces
        .split('/')
        .map(part => part.charAt(0).toUpperCase() + part.slice(1))
        .join(' - ');
    }
    // Set the document title
    const title = `${defaultTitle} | ${routeName}`;
    // Update the document title
    document.title = title;
  };

  useEffect(async () => {
    updateTitle();
    intervalBackendVersion = setInterval(async () => {
      await checkBackendMaintenance();
    }, 5000);
    let wait = await checkBackendMaintenance();
    if (!wait) {
      await logStatus();
      window.scrollTo(0, 0);
      if (!loadedContent) {
        setTimeout(() => {
          if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
          }
        }, 0);
        window.scrollTo(0, 0);
        setLoadedContent(true);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (userInfos && Object.keys(userInfos).length) initClarity(userInfos);
  }, [Object.keys(userInfos).length]);

  const routerComponent = useMemo(
    () => <HodlieRouter logStatus={logStatus} />,
    [userInfos]
  );

  return (sharedState.userInfos?.level < teamLevel && isInMaintenance) ||
    isBackendDown ? (
    <ComingSoon
      media={maintenance}
      imgCol={6}
      useImg={false}
      badge={{
        show: true,
        text: textLang.maintenance[lang],
        colorClass: 'secondary'
      }}
      text={textLang.maintenanceText[lang]}
      button={{ show: false }}
      lottieClass="svg-custom"
      cardClass="margin-top-custom"
    />
  ) : !loadedContent ? (
    <div className="center-page">
      <LoadingSpinner subText="Hodlie" useGif={true}></LoadingSpinner>
    </div>
  ) : (
    <div
      className={isFluid ? 'container-fluid' : 'container max-width-container'}
    >
      {loggedIn && pathname !== '/form' && (
        <UsetifulScriptLoader userInfos={userInfos} />
      )}
      {bottomNavPath && <NavbarBottom />}
      {verticalNavPath
        ? (navbarPosition === 'vertical' || navbarPosition === 'combo') && (
            <NavbarVertical />
          )
        : null}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        {topNavPath && <NavbarTop />}
        <Event />
        {routerComponent}
        {!isKanban && (
          <span className="d-none d-sm-block">
            <Footer />
          </span>
        )}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  match: PropTypes.object
};

export default MainLayout;
