import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { TraderProcedure as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import binanceCard from 'assets/img/hodlie/wallet/binance.png';
import InfoPopover from 'components/utilities/InfoPopover';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supportLinks } from 'staticData/support';

const Done = () => {
  const {
    config: { lang, currency },
    userInfos
  } = useContext(AppContext);
  const [exchangeOverview, setExchangeOverview] = useState('');
  const traderMinCap = 25000;

  const getExchangesOverview = async () => {
    let resp = await endpointInterface(
      lang,
      'backend',
      'getUserExchangesOverview',
      'get',
      true,
      {},
      true,
      true,
      1,
      1
    );
    if (resp.validResponse) setExchangeOverview(resp.data['Binance']);
  };

  const statusColor = wallet => {
    if (wallet.error) return 'warning';
    else if (wallet.connected) return 'success';
    else return 'danger';
  };

  useEffect(() => {
    if (!exchangeOverview && userInfos.connected_exchanges?.length > 0)
      getExchangesOverview();
  }, []);

  return (
    <>
      <Row className="my-3">
        <Col>
          <Card>
            <Card.Body className="text-center">
              <h4 className="mb-2">{textLang.doneText[lang]}</h4>
              <h5 style={{ fontSize: '1rem' }}>{textLang.doneText1[lang]}</h5>
              <h5 style={{ fontSize: '1rem' }}>
                {textLang.doneText2[lang]}
                <a
                  target="_blank"
                  href={supportLinks['INVESTOR_&_PRIVATE']}
                  rel="noreferrer"
                >
                  {textLang.here[lang]}
                </a>
                .
              </h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {((exchangeOverview && exchangeOverview?.amount < traderMinCap) ||
        userInfos.connected_exchanges?.length === 0) && (
        <>
          <hr />
          <Row className="text-center my-2">
            <Col>
              {/* No wallet */}
              {userInfos.connected_exchanges?.length === 0 ? (
                <>
                  <h4>{textLang.noWalletTitle[lang]}</h4>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: textLang.noWalletText[lang]
                    }}
                  />
                </>
              ) : exchangeOverview?.amount < traderMinCap &&
                !exchangeOverview.error ? (
                <>
                  <h4>{textLang.under25Title[lang]}</h4>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: textLang.under25Text[lang]
                    }}
                  />
                </>
              ) : (
                <>
                  <h4>{textLang.walletErrorTitle[lang]}</h4>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: textLang.walletErrorText[lang]
                    }}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row
            className="justify-content-center"
            style={{ alignItems: 'center' }}
          >
            <Col sm={4}>
              <Card.Img style={{ borderRadius: '19%' }} src={binanceCard} />
            </Col>
            <Col sm={4}>
              <Row className="pb-3">
                <Flex className="mb-3 mt-4">
                  <h3>{'Binance'}</h3>
                  <SoftBadge
                    className="fs--2 ms-2 mt-1"
                    style={{ lineHeight: 'normal', height: 'fit-content' }}
                    pill
                    bg={statusColor(exchangeOverview)}
                  >
                    {exchangeOverview.error
                      ? textLang.errorWallet[lang]
                      : exchangeOverview.connected
                      ? textLang.connected[lang]
                      : textLang.notConnected[lang]}
                  </SoftBadge>
                  {exchangeOverview.error && (
                    <InfoPopover
                      Text={textLang.errorText[lang]}
                      className="mt-1"
                    />
                  )}
                </Flex>
                <Flex className="mb-1">
                  <h6>
                    <FontAwesomeIcon icon="wallet" className="me-2" />
                    {textLang.wallet[lang]}:
                    <span className="ms-2 fs-1 text-700">
                      {currency}
                      {exchangeOverview.amount != null
                        ? Number(exchangeOverview.amount).toFixed(2)
                        : '0.00'}
                    </span>
                  </h6>
                  <InfoPopover
                    Text={
                      textLang.onExchangePre[lang] +
                      'Binance' +
                      textLang.onExchangePost[lang]
                    }
                  />
                </Flex>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Done;
