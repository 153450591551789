import React, { /*useState,*/ useContext } from 'react';
import {
  Row,
  Card,
  Col,
  Tooltip,
  OverlayTrigger /*, Form*/
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import 'react-toastify/dist/ReactToastify.css';
import LanguageDropdown from 'components/navbar/top/LanguageDropdown';
import AppContext from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Profile as textLang } from 'staticData/languages';
import FormTitle from 'pages/common/FormTitle';
// import { firstProTier } from 'staticData/common';
// import Flex from 'components/common/Flex';
// import InfoPopover from 'components/utilities/InfoPopover';
// import EnableNotifications from 'pages/common/modals/EnableNotifications';
// import { breakpoints } from 'helpers/utils';

const Settings = () => {
  const {
    config: { lang /*, proNotifications*/ },
    userInfos
  } = useContext(AppContext);
  // const [showModalNotification, setShowModalNotification] = useState(false);
  // const [notifications, setNotifications] = useState(proNotifications === true);

  return (
    <>
      {/* <EnableNotifications
        showModalNotification={showModalNotification}
        setShowModalNotification={setShowModalNotification}
        notifications={notifications}
        setNotifications={setNotifications}
        isProfilePage={true}
      /> */}
      <Row md={12}>
        <FormTitle icon="cog" title={textLang.titleSettings[lang]} />
      </Row>
      <Row className="mb-3 gy-3">
        <Card>
          <Card.Body>
            <Row className="pb-2">
              <Row>
                <Col xs={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="user" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={10} sm={7}>
                      <p>
                        <b>{userInfos.username}</b>
                      </p>
                    </Col>
                    <Col xs={2} sm={5}>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changeusername">
                            {textLang.changeUsername[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <Link to="/change-username">
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="edit"
                            ></IconButton>
                          </Link>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row className="pb-2">
              <Row>
                <Col xs={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="key" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={10} sm={7}>
                      <p>
                        Password: <b>*****</b>
                      </p>
                    </Col>
                    <Col xs={2} sm={5}>
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id="changepwd">
                            {textLang.changePassword[lang]}
                          </Tooltip>
                        }
                      >
                        <div style={{ width: 'fit-content' }}>
                          <Link to="/change-password">
                            <IconButton
                              style={{ padding: '0 0.35rem' }}
                              variant="falcon-default"
                              icon="edit"
                            ></IconButton>
                          </Link>
                        </div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            <Row className="pb-2">
              <Row>
                <Col xs={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="flag" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={10} sm={7}>
                      <p>{textLang.language[lang]}</p>
                    </Col>
                    <Col xs={2} sm={5} style={{ paddingLeft: '20px' }}>
                      <LanguageDropdown />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
            {/* <Row className="pb-2">
              <Row>
                <Col xs={1} style={{ width: '3.333333%' }}>
                  <FontAwesomeIcon icon="bell" />
                </Col>
                <Col xs={10} sm={10} md={8} xl={8}>
                  <Row>
                    <Col xs={10} sm={7}>
                      <Flex>
                        <p>{textLang.notifications[lang]}</p>
                        <InfoPopover
                          Text={textLang.proNotificationInfo[lang]}
                        />
                      </Flex>
                    </Col>
                    <Col xs={2} sm={5} style={{ paddingLeft: '20px' }}>
                      <Form.Switch
                        style={{
                          transform: 'scale(1.2)',
                          paddingLeft:
                            window.innerWidth < breakpoints['sm']
                              ? '2.5rem'
                              : '3.5rem'
                        }}
                        className="switch-padding"
                        id="custom-switch"
                        role="button"
                        checked={notifications}
                        defaultChecked={false}
                        disabled={userInfos.pro_tier < firstProTier}
                        onChange={() => setShowModalNotification(true)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row> */}
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

export default Settings;
