import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Row, Modal } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import { RemoveKeys as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Lottie from 'lottie-react';
import { cleanCache } from 'services/cache';
import { trackEvent } from 'services/externals/mixpanel';

const RemoveKeys = ({
  removeKeysShow,
  setRemoveKeysShow,
  exchange,
  setWalletData,
  walletData,
  realoadUserInfo
}) => {
  const {
    config: { lang },
    setConfig
  } = useContext(AppContext);
  const [showCheck, setShowCheck] = useState(false);

  const removeExchangeCredentials = async () => {
    let form = new FormData();
    form.append('exchange', exchange);
    let remExchangeResponse;
    remExchangeResponse = await endpointInterface(
      lang,
      'backend',
      'removeExchangeCredentials',
      'post',
      true,
      form
    );
    if (remExchangeResponse.validResponse) {
      let tmpWallet = walletData;
      tmpWallet[exchange].connected = false;
      setWalletData(tmpWallet);
      setShowCheck(true);
      await cleanCache(0);
      trackEvent('exchangeKeys', {
        status_event: 'remove'
      });
    } else {
      toast.error(remExchangeResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  const handleCloseCheck = () => {
    setRemoveKeysShow(false);
    setTimeout(() => {
      setShowCheck(false);
    }, 1000);
    setConfig('isReal', false);
    setConfig('visualType', 'virtual');
    realoadUserInfo();
  };

  return (
    <>
      <Modal
        show={removeKeysShow}
        onHide={() => setRemoveKeysShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">
            {showCheck ? textLang.checkTitle[lang] : textLang.title[lang]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showCheck ? (
            <Row className="justify-content-center">
              <Lottie
                animationData={checkPrimaryLight}
                loop={true}
                style={{ height: '200px', width: '200px' }}
              />
            </Row>
          ) : (
            <p
              className="text-center"
              dangerouslySetInnerHTML={{ __html: textLang.body[lang] }}
            ></p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {showCheck ? (
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                onClick={() => handleCloseCheck()}
                variant="outline-success"
                className="me-2 mb-1 "
                icon=""
                iconAlign="left"
                transform="shrink-3"
              >
                {textLang.close[lang]}
              </IconButton>
            </Flex>
          ) : (
            <>
              <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
                <IconButton
                  onClick={() => setRemoveKeysShow(false)}
                  variant="outline-secondary"
                  className="me-2 mb-1 "
                  icon="times"
                  iconAlign="left"
                  transform="shrink-3"
                >
                  No
                </IconButton>
              </Flex>
              <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
                <IconButton
                  onClick={() => removeExchangeCredentials()}
                  variant="outline-danger"
                  className="me-2 mb-1 "
                  icon="trash"
                  iconAlign="left"
                  transform="shrink-3"
                >
                  {textLang.confirm[lang]}
                </IconButton>
              </Flex>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

RemoveKeys.propTypes = {
  removeKeysShow: PropTypes.bool.isRequired,
  setRemoveKeysShow: PropTypes.func.isRequired,
  exchange: PropTypes.string.isRequired,
  walletData: PropTypes.object.isRequired,
  setWalletData: PropTypes.func.isRequired,
  realoadUserInfo: PropTypes.func.isRequired
};

export default RemoveKeys;
