import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Spinner
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import { AddKeys as textLang } from 'staticData/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import robot from 'assets/img/animated-icons/robot.json';
import Lottie from 'lottie-react';
import AppContext from 'context/Context';
import Wizard from '../../../common/wizard/Wizard';
import { useHistory } from 'react-router-dom';
import { cleanCache } from 'services/cache';
import { trackEvent } from 'services/externals/mixpanel';
import IconAlert from 'components/common/IconAlert';

const AddKeys = ({
  addKeysShow,
  setAddKeysShow,
  exchange,
  setWalletData,
  walletData,
  walletMapData,
  realoadUserInfo,
  step,
  setStep,
  userTrackingId,
  error_id = ''
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const routerHistory = useHistory();
  const [showCheck, setShowCheck] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [credentials, setCredentials] = useState({
    key: '',
    secret: ''
  });

  const handleFieldChange = e => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseCheck = async createBot => {
    setAddKeysShow(false);
    await realoadUserInfo();
    setTimeout(() => {
      setShowCheck(false);
    }, 1000);
    if (createBot) routerHistory.push('/invest');
  };

  const buttonStyle = {
    border: '2px solid #efb300',
    borderRadius: '3px',
    color: '#efb300',
    backgroundColor: 'transparent',
    padding: '0.375rem 0.75rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    cursor: 'pointer'
  };

  const buttonHoverStyle = {
    backgroundColor: '#efb300',
    color: 'white'
  };

  const handleFastAPI = async () => {
    const baseURL = 'https://accounts.binance.com/en/oauth/authorize';
    const BINANCE_CLIENT_ID = 'MbdfLIZ1ja';
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: BINANCE_CLIENT_ID,
      redirect_uri: 'https://backend.hodlie.finance/binance/webhook',
      state: userTrackingId,
      scope: 'account:status,create:apikey,user:openId'
    });
    await cleanCache(0);
    window.location.href = `${baseURL}?${params.toString()}`;
  };

  const setExchangeCredentials = async () => {
    setDisabledButton(true);
    let form = new FormData();
    form.append('exchange', exchange);
    form.append('credentials', JSON.stringify(credentials));
    let setExchangeCredentialsRepsonse = await endpointInterface(
      lang,
      'backend',
      'setExchangeCredentials',
      'post',
      true,
      form
    );
    setDisabledButton(false);
    if (setExchangeCredentialsRepsonse.validResponse) {
      trackEvent('exchangeKeys', {
        status_event: 'add'
      });
      await cleanCache(0);
      let tmpWallet = walletData;
      tmpWallet[exchange].connected = true;
      setWalletData(tmpWallet);
      setShowCheck(true);
      setStep(step + 1);
    } else {
      var check = true;
      if (check) {
        toast.error(setExchangeCredentialsRepsonse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  return (
    <Modal
      show={addKeysShow}
      onHide={() =>
        showCheck ? handleCloseCheck(false) : setAddKeysShow(false)
      }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Wizard step={step} />
      </Modal.Header>
      <Modal.Body>
        {showCheck ? (
          <Row className="justify-content-center">
            <h4 className="text-center">{textLang.checkTitle[lang]}</h4>
            <h4 className="text-center">{textLang.checkTitle1[lang]}</h4>
            <Lottie
              animationData={robot}
              loop={true}
              style={{ height: '200px', width: '200px' }}
            />
          </Row>
        ) : (
          <>
            <h4 className="text-center mt-1 pb-4">
              {textLang.connect[lang] + exchange + textLang.wallet[lang]}
            </h4>
            {error_id ? (
              <IconAlert key="danger" variant="danger" className="ms-2">
                {textLang.errorMsg?.[error_id]?.[lang] ||
                  textLang.errorMsg['000001'][lang]}
              </IconAlert>
            ) : null}
            <Row className="justify-content-center pb-3">
              <Col md={6} className="pb-2 text-center">
                <span
                  onClick={handleFastAPI}
                  style={buttonStyle}
                  onMouseOver={e =>
                    Object.assign(e.target.style, buttonHoverStyle)
                  }
                  onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
                >
                  <FontAwesomeIcon icon="link" className="me-2" />
                  {textLang.fastApiConnect[lang]}
                </span>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={8}>
                <hr />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <h4 className="text-center mt-1 p-3">{textLang.or[lang]}</h4>
            </Row>
            <Row className="justify-content-center">
              {addKeysShow && (
                <>
                  <h6 className="text-center pb-2">
                    <FontAwesomeIcon icon="link" className="me-2 pb-2" />
                    {textLang.follow[lang]}
                    <a
                      href={walletMapData[exchange].guideLink}
                      target="__blank"
                    >
                      {exchange + textLang.guide[lang]}
                    </a>
                    {textLang.toConn[lang]}
                    {textLang.yourWall[lang]}
                  </h6>
                </>
              )}
            </Row>
            <Row className="justify-content-center">
              <Col md={10} className="pb-2">
                <div className="border rounded p-3">
                  <Form.Label>API Key</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      type="text"
                      name="key"
                      id="key"
                      autoComplete="new-password"
                      onChange={handleFieldChange}
                    />
                  </InputGroup>
                  <Form.Label>Secret Key</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      type="text"
                      name="secret"
                      id="secret"
                      autoComplete="new-password"
                      onChange={handleFieldChange}
                    />
                  </InputGroup>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showCheck ? (
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              onClick={() => handleCloseCheck(true)}
              variant="outline-success"
              className="me-2 mb-1 "
              icon="robot"
              iconAlign="left"
              transform="shrink-3"
            >
              {textLang.create[lang]}
            </IconButton>
          </Flex>
        ) : (
          <>
            <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
              <IconButton
                onClick={() => setAddKeysShow(false)}
                variant="outline-secondary"
                className="me-2 mb-1 "
                icon="arrow-left"
                iconAlign="left"
                transform="shrink-3"
              >
                {textLang.cancel[lang]}
              </IconButton>
            </Flex>
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                onClick={() => setExchangeCredentials()}
                variant="outline-success"
                className="me-2 mb-1 "
                icon={disabledButton ? '' : 'check'}
                iconAlign="left"
                transform="shrink-3"
                disabled={
                  !credentials.key || !credentials.secret || disabledButton
                }
              >
                {disabledButton ? (
                  <Flex>
                    <Spinner
                      animation="border"
                      className="me-2 mt-1"
                      style={{ width: '1rem', height: '1rem' }}
                    ></Spinner>
                    {textLang.confirm[lang]}
                  </Flex>
                ) : (
                  textLang.confirm[lang]
                )}
              </IconButton>
            </Flex>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

AddKeys.propTypes = {
  addKeysShow: PropTypes.bool.isRequired,
  setAddKeysShow: PropTypes.func.isRequired,
  exchange: PropTypes.string.isRequired,
  walletData: PropTypes.object.isRequired,
  setWalletData: PropTypes.func.isRequired,
  walletMapData: PropTypes.object.isRequired,
  realoadUserInfo: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  userTrackingId: PropTypes.string.isRequired,
  error_id: PropTypes.string.isRequired
};

export default AddKeys;
