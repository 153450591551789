import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { TraderProcedure as textLang } from 'staticData/languages';

const navItems = [
  {
    icon: 'user',
    label: 'KYC'
  },
  {
    icon: 'file',
    label: textLang.docuTitle
  },
  {
    icon: 'dollar-sign',
    label: textLang.payTitle
  },
  {
    icon: 'check',
    label: textLang.doneTitle
  }
];

const WizardLayout = ({ step }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <>
      <Card
        as={Form}
        noValidate
        className="theme-wizard"
        style={{
          backgroundColor: 'var(--falcon-body-bg)',
          width: '-webkit-fill-available',
          boxShadow: 'unset'
        }}
      >
        <Nav className="justify-content-center">
          {navItems.map((item, index) => (
            <NavItem
              key={index}
              index={index}
              step={step}
              icon={item.icon}
              label={
                typeof item.label === 'object' ? item.label[lang] : item.label
              }
            />
          ))}
        </Nav>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step < navItems.length - 1 ? step > index : true,
          active: step < navItems.length - 1 ? step === index : false
        })}
        style={{ cursor: 'default' }}
      >
        <span className="nav-item-circle-parent px-0 ">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  step: PropTypes.number
};

NavItem.propTypes = {
  step: PropTypes.number,
  index: PropTypes.number,
  icon: PropTypes.string,
  label: PropTypes.string
};

export default WizardLayout;
