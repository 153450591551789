import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Spinner, Card } from 'react-bootstrap';
import { TraderProcedure as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { StripeEmbedded } from 'services/externals/stripe/components';
import Flex from 'components/common/Flex';
import { breakpoints } from 'helpers/utils';
import ContactSection from './ContactSection';

const Payment = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [checkoutSession, setCheckoutSession] = useState('');
  const [errorSub, setErrorSub] = useState(false);

  const createSubscriptionTrader = async () => {
    let form = new FormData();
    form.append('tolt_referral', window.tolt_referral || '');
    form.append('embedded', true);
    const resp = await endpointInterface(
      lang,
      'backend',
      'createTraderSubscription',
      'post',
      true,
      form
    );
    if (resp.responseStatus !== 200) {
      setErrorSub(true);
      return;
    }
    setCheckoutSession(resp.data.client_secret);
  };

  useEffect(() => {
    if (!checkoutSession) createSubscriptionTrader();
  }, []);

  return (
    <>
      {!checkoutSession ? (
        !errorSub ? (
          <Flex className="mt-4 justify-content-center">
            <Spinner />
          </Flex>
        ) : (
          <>
            <Row>
              <Col className="h-100">
                <Card className="text-center">
                  <Row>
                    <Col>
                      <Card.Body>
                        <Card.Title>{textLang.error[lang]}</Card.Title>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col>
                <ContactSection vertical={false} page={'payment'} />
              </Col>
            </Row>
          </>
        )
      ) : (
        <>
          <Row>
            <Col>
              <StripeEmbedded
                checkoutSession={checkoutSession}
                style={
                  window.innerWidth > breakpoints['sm']
                    ? { minWidth: '992px' }
                    : {}
                }
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col xxl={9}>
              <ContactSection vertical={false} page={'payment'} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Payment;
