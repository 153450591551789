import React, { useContext } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { TraderProcedure as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import IconItem from 'components/common/icon/IconItem';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { supportDirectContact } from 'staticData/support';

const ContactSection = ({ vertical = true, page }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  const titleMap = {
    document: textLang.titleDocument[lang],
    payment: textLang.titlePayment[lang]
  };

  const textMap = {
    document: textLang.textDocument[lang],
    payment: textLang.textPayment[lang]
  };

  return (
    <Card
      style={{ backgroundImage: 'linear-gradient(135deg, #2c59ce, #001835)' }}
    >
      <Card.Header className="pb-0">
        <h3 className="text-dark mb-0">{titleMap[page] || ''}</h3>
      </Card.Header>
      <Card.Body>
        <p className="text-dark">{textMap[page] || ''}</p>
        <Row>
          <Col xs={vertical ? 12 : 6}>
            <Row
              as={Link}
              to={{ pathname: `tel:${supportDirectContact.WA_MOBILE}` }}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              className="position-relative align-items-center py-2 hover-bg-500 rounded"
            >
              <Col xs={12} sm={9} className="py-1">
                <Flex className="align-items-center">
                  <div className=" me-3" style={{ position: 'relative' }}>
                    <IconItem
                      tag="div"
                      icon={'phone-alt'}
                      size="xl"
                      iconClass="fs-1"
                      className="shadow-none"
                    />
                  </div>
                  <h6 className="mb-0">{supportDirectContact.WA_MOBILE}</h6>
                </Flex>
              </Col>
            </Row>
          </Col>
          <Col xs={vertical ? 12 : 6}>
            <Row
              as={Link}
              to={{ pathname: supportDirectContact.WA_LINK }}
              target="_blank"
              style={{ textDecoration: 'none', color: 'inherit' }}
              className="position-relative align-items-center py-2 hover-bg-500 rounded"
            >
              <Col xs={12} sm={9} className="py-1">
                <Flex className="align-items-center">
                  <div className=" me-3" style={{ position: 'relative' }}>
                    <IconItem
                      tag="div"
                      icon={'envelope'}
                      size="lg"
                      iconClass="fs-2"
                      className="shadow-none"
                    />
                  </div>
                  <h6 className="mb-0">{textLang.sendMsg[lang]}</h6>
                </Flex>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ContactSection.propTypes = {
  vertical: PropTypes.bool,
  page: PropTypes.string
};

export default ContactSection;
