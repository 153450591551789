import React, { useContext, useState, useEffect } from 'react';
import { Col, Row, Card, Spinner, Accordion, Button } from 'react-bootstrap';
import { TraderProcedure as textLang, Billings } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import AccordionItem from './AccordionItem';
import Flex from 'components/common/Flex';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import ContactSection from './ContactSection';
import PropTypes from 'prop-types';
import { Docusign } from 'services/externals/docusign/components';
import { breakpoints } from 'helpers/utils';

const Document = ({ newStep, setStep }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [documentUrl, setDocumentUrl] = useState();
  const [documentReady, setDocumentReady] = useState(false);
  const [errorContract, setErrorContract] = useState(false);
  const [endSession, setEndSession] = useState(false);
  const [error, setError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const returnURL = 'account/private-procedure';

  const handleClickButton = () => {
    setDisableButton(true);
    window.open(documentUrl, '_blank');
  };

  const createDocument = async () => {
    let form = new FormData();
    form.append('return_url', returnURL);
    const resp = await endpointInterface(
      lang,
      'backend',
      'docusignSignature',
      'post',
      true,
      form
    );
    if (resp.responseStatus !== 200) {
      console.error('Error occurred while creating document');
      setErrorContract(true);
      return;
    }
    setDocumentUrl(resp.data.redirect_url);
  };

  useEffect(() => {
    if (!documentUrl) createDocument();
  }, []);

  return (
    <>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header className="text-center">
              {textLang.docuTitle[lang]}
            </Card.Header>
            <Card.Body>
              <h5 className="mb-5">{textLang.pricesText[lang]}</h5>
              <Row className="justify-content-center mb-5">
                <Col>
                  <Accordion
                    id="accordionFaq"
                    className="border rounded overflow-hidden"
                  >
                    <AccordionItem
                      title={Billings.traderInfrastructure[lang]}
                      description={
                        textLang.traderInfrastructureText[lang] +
                        userInfos.trader.fixed_price +
                        textLang.traderInfrastructureText1[lang]
                      }
                      id={0}
                      isFirst={true}
                      isLast={false}
                    />
                    <AccordionItem
                      title={Billings.performanceFee[lang]}
                      description={
                        textLang.performanceFeeText[lang] +
                        userInfos.trader.performance_fee +
                        textLang.performanceFeeText1[lang]
                      }
                      id={2}
                      isFirst={false}
                      isLast={true}
                    />
                  </Accordion>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Card className="text-center">
            <Row>
              <Col>
                {!endSession ? (
                  <Card.Body>
                    <p
                      className="text-start"
                      dangerouslySetInnerHTML={{
                        __html: textLang.documentText[lang]
                      }}
                    />
                    {documentUrl ? (
                      window.innerWidth >= breakpoints['sm'] ? (
                        // > MOBILE
                        <Docusign
                          url={documentUrl}
                          setEndSession={setEndSession}
                          setError={setError}
                          setStep={setStep}
                          newStep={newStep}
                          documentReady={documentReady}
                          setDocumentReady={setDocumentReady}
                        />
                      ) : (
                        // MOBILE
                        <>
                          <h5>{textLang.mobileText[lang]}</h5>

                          <Button
                            disabled={disableButton}
                            size="sm"
                            onClick={handleClickButton}
                          >
                            {textLang.mobileButton[lang]}
                          </Button>
                        </>
                      )
                    ) : (
                      <Flex
                        className="my-2 justify-content-center align-items-center"
                        style={{
                          height:
                            window.innerWidth >= breakpoints['sm']
                              ? '400px'
                              : '100px'
                        }}
                      >
                        {errorContract ? (
                          <span>{textLang.errorOccurred[lang]}</span>
                        ) : (
                          <Spinner />
                        )}
                      </Flex>
                    )}
                  </Card.Body>
                ) : (
                  <Card.Body>
                    {!error ? (
                      <>
                        <Flex className="justify-content-center">
                          <Lottie
                            animationData={checkPrimaryLight}
                            style={{ height: '120px', width: '120px' }}
                          />
                        </Flex>
                        <h5>{textLang.successfullEndSession[lang]}</h5>
                      </>
                    ) : (
                      <span>{textLang.errorOccurred[lang]}</span>
                    )}
                  </Card.Body>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <ContactSection page={'document'} />
        </Col>
      </Row>
    </>
  );
};
Document.propTypes = {
  newStep: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired
};

export default Document;
