import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';

const GeneralModal = ({
  showModal,
  setShowModal,
  head,
  body,
  button = null
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {head && (
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="contained-modal-title-vcenter">{head}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{body}</Modal.Body>
      {button && (
        <Modal.Footer>
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              variant={button.variant}
              className="me-2 mb-1 "
              icon={button.icon}
              iconAlign="left"
              transform="shrink-3"
              onClick={() => button.buttonFunction()}
            >
              {button.text}
            </IconButton>
          </Flex>
        </Modal.Footer>
      )}
    </Modal>
  );
};

GeneralModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  head: PropTypes.object,
  body: PropTypes.object,
  button: PropTypes.object
};

export default GeneralModal;
