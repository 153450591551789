import React, { useContext, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormTitle from 'pages/common/FormTitle';
import { Profile as textLang } from 'staticData/languages';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { account, miscellaneous } from './quickLinksData';
import GeneralModal from './modals/GeneralModal';
import SoftBadge from 'components/common/SoftBadge';
import TraderModal from 'pages/common/modals/TraderModal';
import {
  firstProTier,
  isTraderCompleted,
  traderProTier
} from 'staticData/common';
import Flex from 'components/common/Flex';

const AccountItem = ({ item, getInfo }) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [traderModalShow, setTraderModalShow] = useState(false);
  const hideItem = item.onlyPro && userInfos.pro_tier < firstProTier;

  const isWannaBeTrader =
    item.onlyWannaBeTrader && userInfos.trader && !isTraderCompleted(userInfos);

  const canShowTradeModal =
    item.onlyWannaBeTrader &&
    !isWannaBeTrader &&
    userInfos.pro_tier < traderProTier;

  if (!isTraderCompleted(userInfos) || !item.onlyWannaBeTrader) {
    if (
      (isWannaBeTrader && userInfos.pro_tier === traderProTier) ||
      canShowTradeModal
    )
      item.title = 'Private';
    return (
      <>
        {(item.modalHead || item.modalBody) && (
          <GeneralModal
            showModal={showModal}
            setShowModal={setShowModal}
            head={item.modalHead}
            body={
              typeof item.modalBody === 'object'
                ? item.modalBody[lang]
                : item.modalBody
            }
          />
        )}
        {item.onlyWannaBeTrader && (
          <TraderModal
            showModal={traderModalShow}
            setShowModal={setTraderModalShow}
            getInfo={getInfo}
          />
        )}
        <Col
          md={6}
          xxl={4}
          onClick={() =>
            item.modalHead || item.modalBody
              ? setShowModal(true)
              : canShowTradeModal
              ? setTraderModalShow(true)
              : null
          }
          style={hideItem ? {} : { cursor: 'pointer' }}
          className="my-2"
        >
          {/* start DESKTOP VERSION */}
          <div
            className={
              (hideItem ? '' : 'hover-bg-100') +
              (isWannaBeTrader ? ' border border-success ' : '') +
              ' p-3 py-x1 text-center rounded-3 position-relative d-none d-sm-block'
            }
          >
            <div
              className={`icon-item icon-item-xl shadow-none mx-auto mt-x1 bg-soft-${item.color}`}
              style={hideItem ? { opacity: '.2' } : {}}
            >
              {item.icon}
            </div>
            <h5 className="mt-3 mb-2">
              {item.modalHead || item.modalBody || canShowTradeModal ? (
                <span className="text-900 hover-primary stretched-link">
                  {typeof item.title === 'object'
                    ? item.title[lang]
                    : item.title}
                </span>
              ) : hideItem ? (
                <>
                  <span style={hideItem ? { opacity: '.2' } : {}}>
                    {typeof item.title === 'object'
                      ? item.title[lang]
                      : item.title}
                  </span>
                </>
              ) : (
                <Link
                  to={item.link ? item.link : '#'}
                  className={'text-900 hover-primary stretched-link'}
                  style={{
                    textDecoration: 'none'
                  }}
                >
                  {typeof item.title === 'object'
                    ? item.title[lang]
                    : item.title}
                </Link>
              )}
            </h5>
            <h6
              className="w-75 mx-auto text-600 mb-x1"
              style={hideItem ? { opacity: '.2' } : {}}
            >
              {typeof item.description === 'object'
                ? item.description[lang]
                : item.description}
            </h6>
          </div>
          {/* end DESKTOP VERSION */}
          {/* start MOBILE VERSION */}
          <Flex
            alignItems="center"
            className={
              (isWannaBeTrader ? 'border-success' : '') +
              ' p-3 py-x1 bg-light rounded-3 border position-relative d-sm-none'
            }
          >
            {item.icon}
            <div className="ms-3 my-x1">
              <h5 className="fs-0 fw-semi-bold mb-2">
                {item.modalHead || item.modalBody || canShowTradeModal ? (
                  <span className="text-900 hover-primary stretched-link">
                    {typeof item.title === 'object'
                      ? item.title[lang]
                      : item.title}
                  </span>
                ) : hideItem ? (
                  <>
                    <span style={hideItem ? { opacity: '.2' } : {}}>
                      {typeof item.title === 'object'
                        ? item.title[lang]
                        : item.title}
                    </span>
                    <SoftBadge bg={'warning'} className="ms-2">
                      {'only pro'}
                    </SoftBadge>
                  </>
                ) : (
                  <Link
                    to={item.link ? item.link : '#'}
                    className={'text-900 hover-primary stretched-link'}
                    style={{
                      textDecoration: 'none'
                    }}
                  >
                    {typeof item.title === 'object'
                      ? item.title[lang]
                      : item.title}
                  </Link>
                )}
              </h5>
              <h6
                className="mb-0 text-600"
                style={hideItem ? { opacity: '.2' } : {}}
              >
                {typeof item.description === 'object'
                  ? item.description[lang]
                  : item.description}
              </h6>
            </div>
          </Flex>
          {/* end MOBILE VERSION */}
        </Col>
      </>
    );
  } else return <></>;
};

AccountItem.propTypes = {
  item: PropTypes.object.isRequired,
  getInfo: PropTypes.function
};

const index = ({ getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return (
    <>
      <Row md={12}>
        <FormTitle icon="user" title={textLang.title[lang]} />
      </Row>
      <Row className="mb-3 gy-3">
        <Card className="mt-3">
          <Card.Body>
            <Row className="gx-3">
              {account.map((item, index) => (
                <AccountItem key={index} item={item} getInfo={getInfo} />
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Row>
      <Row className="mb-3 gy-3">
        <Card className="mt-3">
          <Card.Header className="border-bottom border-200">
            <h6 className="mb-0">Info</h6>
          </Card.Header>
          <Card.Body>
            <Row className="gx-3">
              {miscellaneous.map((item, index) => (
                <AccountItem key={index + account.length} item={item} />
              ))}
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};

index.propTypes = {
  getInfo: PropTypes.function
};

export default index;
